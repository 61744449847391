<template>
    <div>
        <s-crud
            title="Fasa de Seleccion empaque"
            ref="gridpackinglines"
            excel
            edit
            add
            @save="save($event)"
            :filter="filter"
            sorteable
            remove
            :config="config"
            search-input
            >
            <template v-slot="props">
                <v-container>
                    <v-row >
                        <v-col cols="3" lg="3" md="6" sm="12">
                            <s-text label="Descripcion"
                            v-model="props.item.PklDescription"
                            ref="PklDescription"></s-text>
                        </v-col>
                        <v-col cols="3" lg="3" md="6" sm="12">
                            <s-select 
                                :items="ItemsExitCategories"
                                label="Salida de acondicionado"                                
								item-value="CecID"                                
								item-text="CecDescription"
                                v-model="props.item.CecID">
                            </s-select>
                        </v-col>
                        <v-col>
                            <v-switch label="Seleccion de Byp"
                                v-model="PklByp">

                            </v-switch>
                        </v-col>

                        <v-col cols="3" lg="3" md="6" sm="12"> 
                            <s-select-definition                                 
                                :def=1154 label="Estado" 
                                v-model=props.item.PklStatus>
                            </s-select-definition>  
                        </v-col>
                    </v-row>
                </v-container>
            </template>
            <template v-slot:PklStatus="{row}">
                <v-icon :color="row.PklStatus == 1 ? 'success': 'default'">mdi-checkbox-blank-circle</v-icon>
            </template>
        </s-crud>
    </div>
</template>


<script>

    import _sPackingLines from '@/services/FrozenProduction/FrzPackingLines.js'
    import _sFrzTypeExitCategoryService from "@/services/FrozenProduction/FrzTypeExitCategory.js"
    export default
    {
        data: () => {
            return{
                ItemsExitCategories: [],
                filter: {},
                config:{                    
                    service : _sPackingLines,
                    headers: [                            
                        {text: "ID", value: "PklID",  align: "center", width: "10%", sortable: true},
                        {text: "Descripcion", value: "PklDescription",  align: "center", width: "80"},                        
                        {text: "Estado", value: "PklStatus",  align: "center", width: "30"},
                        {text: "Salida de acondicionado", value: "CecDescription",  align: "center", width: "30"},
                    ],
                    model:{
                        PklID: "ID",
                        PklStatus: "PklStatus",
                    },
                PklByp: 0,

                }
            }
        },
        methods: {
        
            save(item)
            {                  
                if(item.PklDescription == ""){
                    this.$fun.alert("Ingrese descripcion", "warning");
                    this.$refs.PklDescription.focus();
                    return;
                }        
                item.UsrID = this.$fun.getUserID();     
                item.PklByp = this.PklByp ? 1 : 0;
 
                item.save();
            },
            initialize()
            {
                _sFrzTypeExitCategoryService.listexitcategoria({}, this.$fun.getUserID()).then(r =>{
                    if(r.status == 200)
                    {
                        this.ItemsExitCategories = r.data;                    
                    }
                });            
            }
        },
        created()
        {
            this.initialize()
        },
        
    }
</script>